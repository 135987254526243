(function() {
    angular.module('EntrakV5').controller('distributorController', distributorController);

    function distributorController($scope, $rootScope, Service, KEY, Api, $state) {
        console.log('distributorController');

        var caller = Api.createApiCaller();
        $scope.btnStatus = {};

    /* first load */
        $rootScope.loadingPage = 1;
        caller.call(Api.getLandlords()).then(function(res){
            $scope.distributors = res;
            $rootScope.loadingPage--;
        }, function(err){
            if (err === KEY.ignore)
                return;
            $rootScope.loadingPage--;
            alert(Service.translate("error.generalGetDataFail"));
        });
    /* first load */

    /* step1 */
        $scope.addDistributorWinData = {};

        $scope.addDistributorWinOpt = {
            title: Service.translate("distributor.addDistributor"),
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false
        }

        $scope.confirmAddDistributor = function(){
            $scope.btnStatus.saving = true;
            var name = $scope.addDistributorWinData.name.trim();
            caller.call(Api.createLandlord(name)).then(function(res){
                $scope.distributors.push(res);
                $scope.btnStatus.saving = false;
                $scope.addDistributorWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                if (err.graphQLErrors && (err = err.graphQLErrors[0])){
                    if (err.message === 'invalid tenant dns'){//TODOricky check err msg
                        alert(Service.translate("error.domainNotFound"));
                    } else {
                        alert(Service.translate("error.generalCreateLandlord"));
                    }
                } else {
                    alert(Service.translate("error.generalCreateLandlord"));
                }
                $scope.btnStatus.saving = false;
            });
        }
        $scope.addDistributor = function(){
            $scope.addDistributorWinData = {
                name: "",
            }
            $scope.addDistributorWin.open().center();
        }

        $scope.confirmDeleteDistributor = function(){
            var id = $scope.selectedDeleteItem.id;
            caller.call(Api.deleteLandlord(id)).then(function(res){
                Service.deleteArrItem($scope.distributors, id);
                $rootScope.deletePopup.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                alert(Service.translate("error.generalDeleteFail"));
            });
        }
        $scope.deleteDistributor = function(d){
            $scope.selectedDeleteItem = d;
            $rootScope.deletePopup.show("distributor.popup.deleteDistributor", "distributor.popup.deleteDistributorDesc", d.name, $scope.confirmDeleteDistributor);
        }

        $scope.selectDistributor = function(d){
            $state.go("distributorDetail", { distributorId: d.id});
        }
    /* step1 */

        $scope.$on('$destroy', function() {
            console.log("distributorController destroy");
            caller.cancel();
        });
    }
})();
